import React, {useEffect} from 'react';
import Frame from "../../components/frame";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast, useToasterStore } from 'react-hot-toast';
import { userRegister, userSendOtp, userVerifyOtp } from '../../api/auth';
import { validateEmail } from '../../utils/helper';

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      username:"",
      email:"",
      dob:"",
      password:"",
      view_pass:false,
      view_pass_c:false,
      password_c:"",
      terms:"",
      sponsor:this.props.sponsor?this.props.sponsor:'',
      avatar:"",
      input_otp:'',
      is_verify:false,
      is_send:false
    }
  }

  doRegister = async(e)=>{
    try {
      e.preventDefault();
      let checkbox=document.getElementById("agreed");    
      if(!this.state.username){
        toast.error('Username is required!!');
        return false;
      }
      if(this.state.username.includes(' ')){
        toast.error('Invalid Username!!');
        return false;
      }
      if(!this.state.email){
        toast.error('Email is required!!');
        return false;
      }
      if(!validateEmail(this.state.email)){
        toast.error('Invalid Email!!');
        return false;
      }
      if(!this.state.dob){
        toast.error('DOB is required!!');
        return false;
      }
      if(!this.state.password){
        toast.error('Password is required!!');
        return false;
      }
      if(!this.state.password_c){
        toast.error('Confirm Password is required!!');
        return false;
      }
      if(this.state.password!=this.state.password_c){
        toast.error('Password and confirm is mismatched!!');
        return false;
      }
      if(!checkbox.checked){
        toast.error('Please accept term and conditions!!');
        return false;
      }
      this.setState({loading:true});
      let input_data = {
        username:this.state.username,
        email:this.state.email,
        dob:this.state.dob,
        password:this.state.password,
        terms:"1",
        sponsor:this.state.sponsor,
        avatar:""
      };
      let response = await userRegister(input_data);
      console.log(response);
      if(response.status){
        this.setState({
          loading: false,
          username:"",
          email:"",
          dob:"",
          password:"",
          view_pass:false,
          view_pass_c:false,
          password_c:"",
          terms:"",
          sponsor:"",
          avatar:""
        });
        toast.success(response?.msg);
        this.props.navigate('/login');
      }else{
        if(response?.errors?.username){
          toast.error(response?.errors?.username?.toString());
        }else if(response?.errors?.email){
          toast.error(response?.errors?.email?.toString());
        }else if(response?.errors?.sponsor){
          toast.error(response?.errors?.sponsor?.toString());
        }else if(response?.msg){
          toast.error(response?.msg?.toString());
        }else{
          toast.error('Server error!!');
        }
        this.setState({loading:false});
      }
    } catch (error) {
      toast.error(error?.toString());
      this.setState({loading:false});
    }
  }

  sendOTP = async() => {
    try {
      if(!this.state.email){
        toast.error('Email is required!!');
        return false;
      }
      if(!validateEmail(this.state.email)){
        toast.error('Invalid Email!!');
        return false;
      }
      this.setState({loading:true});
      
      let input_data = {
        mobile_or_email:this.state?.email
      };
      let res_data = await userSendOtp(input_data);
      if(res_data.status){
        this.setState({is_send:true});
      }
      this.setState({loading:false});
    } catch (error) {
      this.setState({loading:false});
    }
  }

  verifyOTP = async() => {
    try {
      if(!this.state.email){
        toast.error('Email is required!!');
        return false;
      }
      if(!this.state.input_otp){
        toast.error('OTP is required!!');
        return false;
      }
      this.setState({loading:true});
      
      let input_data = {
        mobile_or_email:this.state?.email,
        otp:this.state.input_otp
      };
      let res_data = await userVerifyOtp(input_data);
      if(res_data.status){
        toast.success(res_data.msg);
        this.setState({is_verify:true});
      }else{
        if(res_data?.errors?.otp){
          toast.error(res_data?.errors?.otp);
        }else{
          toast.error(res_data?.msg);
        }
        
      }
      this.setState({loading:false});
    } catch (error) {
      this.setState({loading:false});
    }
  }

  render() {

    return (
      <Frame withHeader={false} withFooter={false}>
        {this.state.loading ? (
          <div className="loader-container">
            <div className="page_loader"></div>
          </div>
        ) : null}
        <div className="header header-style2 fixed-top d-flex align-items-center">
          <Link to="/login" className="left back-btn"><i className="icon-left-btn"></i></Link>
        </div>
        <div className="pt-45 pb-20">
          <div className="tf-container mt-40">
            <img className="login_logo mb-25" src="/assets/img/logo.png" alt="" />
            <div className="position-relative">
              <div className="login_box">
                <h6 className="mb-20">Let's start off with entering your email ID.</h6>

                <div className="mb-18">
                  <p className="mb-8 text-small">Username</p>
                  <input className="input_area" type="text" placeholder="Username"
                    onChange={(e)=>this.setState({username:e.target.value})}
                    value={this.state.username}
                  />
                </div>
                <div className="mb-18">
                  <p className="mb-8 text-small"> Email</p>
                  <input className="input_area" type="text" placeholder="Example@gmail"
                    onChange={(e)=>this.setState({email:e.target.value})}
                    value={this.state.email}
                    disabled={this.state.is_verify?true:false}
                  />
                </div>

                {!this.state.is_verify?(
                  <div className="mb-14">
                    <p className="mb-8 text-small">OTP</p>
                    <div className="position-relative box-input-field">
                      <input className="input_area" type="number" placeholder="OTP"
                        value={this.state.input_otp}
                        onChange={(e)=>this.setState({input_otp:e.target.value})}
                      />
                      <div className="mt-8">
                        <span onClick={this.sendOTP} className="float-right w-600 text-primary pointer">Send Otp</span>
                      </div>
                      
                    </div>
                  </div>
                ):(null)}

                <div className="mb-18">
                  <p className="mb-8 text-small"> DOB</p>
                  <input className="input_area" type="date" placeholder=""
                    onChange={(e)=>this.setState({dob:e.target.value})}
                    value={this.state.dob}
                  />
                </div>

                <div className="mb-18">
                  <p className="mb-8 text-small">Password</p>
                  <div className="box-auth-pass">
                    {this.state.view_pass ? (
                      <input type="text" required placeholder="Your password" className="input_area password-field"
                        onChange={(e)=>this.setState({password:e.target.value})}
                        value={this.state.password}
                      />
                    ):(
                      <input type="password" required placeholder="Your password" className="input_area password-field"
                        onChange={(e)=>this.setState({password:e.target.value})}
                        value={this.state.password}
                      />
                    )}
                    
                    <span className="show-pass" onClick={()=>this.setState({view_pass:!this.state.view_pass})}>
                      {!this.state.view_pass ? (
                        <i className="icon-view"></i>
                      ):(
                        <i className="icon-view-hide"></i>
                      )}
                    </span>
                  </div>
                </div>
                <div className="mb-18">
                  <p className="mb-8 text-small">Confirm Password</p>
                  <div className="box-auth-pass">
                    {this.state.view_pass_c ? (
                      <input type="text" required placeholder="Your password" className="input_area password-field2"
                        onChange={(e)=>this.setState({password:e.target.value})}
                        value={this.state.password}
                      />
                    ):(
                      <input type="password" required placeholder="Your password" className="input_area password-field2"
                        onChange={(e)=>this.setState({password_c:e.target.value})}
                        value={this.state.password_c}
                      />
                    )}                    
                    <span className="show-pass2" onClick={()=>this.setState({view_pass_c:!this.state.view_pass_c})}>
                      {!this.state.view_pass ? (
                        <i className="icon-view"></i>
                      ):(
                        <i className="icon-view-hide"></i>
                      )}
                    </span>
                  </div>
                </div>

                <div className="mb-18">
                  <p className="mb-8 text-small"> Sponsor</p>
                  <input className="input_area" type="text" placeholder="Sponsor"
                    onChange={(e)=>this.setState({sponsor:e.target.value})}
                    value={this.state.sponsor}
                  />
                </div>

                <div className="mb-18">
                  <input type="checkbox" placeholder="terms" id="agreed"
                    onChange={(e)=>console.log(e.target.value)}
                    value={this.state.terms}
                  />
                  <a className='check_approve'>I Accept the terms and conditions.</a>
                </div>

                {this.state.is_verify?(
                  <button disabled={this.state.loading} onClick={this.doRegister} className="tf-btn primary md mt-20">Create an account</button>
                ):(
                  <button disabled={this.state.loading} onClick={this.verifyOTP} className="tf-btn primary md mt-20">Verify</button>
                )}                
              </div>
              <div className="shape_two"></div>
              <div className="shape_one"></div>
            </div>

          </div>
        </div>

      </Frame >
    );
  }
}

function RegisterHOC(props) {
  let { sponsor } = useParams();  
  const navigate = useNavigate();
  const { toasts } = useToasterStore();
  useEffect(() => {
    toasts.filter((t) => t.visible).filter((_, i) => i >= 1)
    .forEach((t) => toast.dismiss(t.id));
  }, [toasts]);

  return <Register
    sponsor={sponsor}
    navigate={navigate}
    {...props}
  />;
}

export default RegisterHOC;