import React,{useEffect, useState} from 'react';
import Frame from "../components/frame";
import { Link } from 'react-router-dom';
import moment from 'moment/moment';
import { userTransactions } from '../api/auth';
import { CurrencySymbol } from '../utils/constants';


class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    }
  }


  render() {
    let this2 = this;
    return (
      <Frame withHeader={false} withFooter={true}>
        {this.props.is_fetching ? (
          <div className="loader-container">
            <div className="page_loader"></div>
          </div>
        ) : null}
        <div className="header header-style2 fixed-top bg-menuDark d-flex align-items-center">
          <Link to="/wallet" className="left back-btn"><i className="icon-left-btn"></i></Link>
          <h5 className="mb-0">Transaction History</h5>
          <div className="d-flex align-items-center gap-8 header_r_p">
            <a className="icon_circle"><i className="icon icon-question"></i></a>
          </div>
        </div>

        <div className="pt-55 pb-70">
          <div className="tf-container">
            <ul className="" style={{marginTop:this.state.view_chart?20:0}}>
              {this.props.tx_list?.length>0?(                    
                this.props.tx_list?.map(function(item,index){
                  return(
                    <li className='leauges_game_box' key={`tx${index}`}
                    >
                      <p>Amount: {parseFloat(item.amount).toFixed(2)} {CurrencySymbol}</p>
                      <p>Type: {item.type}</p>
                      <p>Source: {item.source}</p>
                      <p>Wallet: {item.wallet}</p>
                      {item.reference_id?(
                        <p>Game Id: {item.reference_id}</p>
                      ):(null)}
                      <p>Date: {moment(item.created_at).format('LLL')}</p>
                    </li>
                  )
                })
              ):(
                <>
                  <img className='no-img' src="/assets/img/bear-new.png" alt="" />
                  <p className='text-center'>No Data Available!!</p>
                </>
              )}
            </ul>
          </div>
        </div>

      </Frame >
    );
  }
}

function HomeHOC(props) {
  const [tx_list, setTxList] = useState([]);
  const [is_fetching, setFetching] = useState(true);
  const [load_data,setLoadData] = useState(true);

  useEffect(() => {
    (async () => {
      setLoadData(false);
      if(is_fetching){
        getTxList();
      }
    })();

    return () => {
      // this now gets called when the component unmounts 
    };

  }, [tx_list]);

  const getTxList = async()=>{
    let token = localStorage.getItem('token');
    let user = localStorage.getItem('user_details');
    user = JSON.parse(user);
    let tx_list = await userTransactions(token, user.id);
    if (tx_list.status) {      
      setTxList(tx_list.data);
      setFetching(false);
    }
  }

  
  return <Home
    tx_list={tx_list}
    is_fetching={is_fetching}
    {...props}
  />;
}

export default HomeHOC;